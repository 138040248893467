
































































































































































































































































































































































































































































































































































































import {
  defineComponent,
  computed,
  onBeforeMount,
  ref,
  watchEffect,
} from "@vue/composition-api";
import {
  Applicant,
  ApplicantFile,
  ApplicantNote,
  User,
  Provider,
} from "@/store";
import {
  useStore,
  useApplicantService,
  useRouter,
  useUserService,
  useDogService,
} from "@/composables/providers";
import moment from "moment";
import { ApplicantActions, ApplicantGetters } from "@/store/applicant";
import ConfirmationDialog from "@/components/dialogs/Confirmation.vue";
import FileDialog from "@/components/dialogs/File.vue";
import { UserActions, UserGetters } from "@/store/user";
import { DogActions } from "@/store/dog";
import {
  SPECIALTIES,
  APPLICANT_STATUS_LIST,
  SPONSOR_RELATIONSHIP,
  CONTACT_RELATIONSHIP,
} from "@/store/constants";

export default defineComponent({
  name: "ApplicantDetail",
  components: {
    ConfirmationDialog,
    FileDialog,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const userService = useUserService();
    const service = useApplicantService();
    const dogService = useDogService();
    const id = computed<string>(() => router.currentRoute.params["id"] || "");
    const loading = computed<boolean>(
      () => store.getters[ApplicantGetters.LOADING]
    );
    const selected = computed<Applicant | undefined>(
      () => store.getters[ApplicantGetters.SELECTED]
    );

    const serviceEmploymentFiles = computed<ApplicantFile[] | undefined>(() =>
      ((selected.value || {}).attachments || []).filter(
        (attachment) => attachment.type === "serviceEmploymentFile"
      )
    );
    const medicalFiles = computed<ApplicantFile[] | undefined>(() =>
      ((selected.value || {}).attachments || []).filter(
        (attachment) => attachment.type === "medicalFile"
      )
    );
    const homeVisitFile = computed<ApplicantFile[] | undefined>(() =>
      ((selected.value || {}).attachments || []).filter(
        (attachment) => attachment.type === "homeVisitFile"
      )
    );
    const applicationFile = computed<ApplicantFile[] | undefined>(() =>
      ((selected.value || {}).attachments || []).filter(
        (attachment) => attachment.type === "applicationFile"
      )
    );
    const contractFile = computed<ApplicantFile[] | undefined>(() =>
      ((selected.value || {}).attachments || []).filter(
        (attachment) => attachment.type === "contractFile"
      )
    );

    const users = computed<User[]>(() => store.getters[UserGetters.USERS]);
    const notes = computed<ApplicantNote[] | undefined>(() => {
      const notes = (
        ((selected.value || {}).notes as ApplicantNote[]) || []
      ).sort((a: ApplicantNote, b: ApplicantNote) => {
        const dateA = new Date(a.createDate).getTime();
        const dateB = new Date(b.createDate).getTime();

        if (dateA > dateB) {
          return 1;
        }
        if (dateB > dateA) {
          return -1;
        }
        return 0;
      });
      return notes;
    });

    const lastAddressLine = ref<string>("");
    const displayPhone = ref<string>("");
    const displayHomePhone = ref<string>("");
    const displaySponsorPhone = ref<string>("");
    const displayEmergencyContactPhone = ref<string>("");
    const isDefaultPhoto = computed<boolean>(() => {
      return !(selected.value || {}).displayPhoto;
    });
    const photoSource = computed<string | undefined>(() => {
      if ((selected.value || {}).displayPhoto) {
        return (selected.value || {}).displayPhoto;
      } else {
        return `${require("@/assets/placeholder.png")}`;
      }
    });

    const action = computed<string>(() =>
      selected.value && selected.value.deleted ? "Reactivate" : "Delete"
    );
    const applicantMilitaryService = computed<string>(
      () => store.getters[ApplicantGetters.SERVICES_MILITARY_STRING]
    );
    const applicantResponderService = computed<string>(
      () => store.getters[ApplicantGetters.SERVICES_RESPONDER_STRING]
    );

    onBeforeMount(async () => {
      await Promise.all([
        store.dispatch(ApplicantActions.SELECT, {
          id: id.value,
          service: service,
        }),
        store.dispatch(UserActions.LIST, {
          service: userService,
        }),
      ]);
    });

    function getFormattedPhone(phone: string) {
      const areaCode = phone.slice(2, 5);
      const prefix = phone.slice(5, 8);
      const line = phone.slice(8, 13);
      return `(${areaCode}) ${prefix}-${line}`;
    }

    watchEffect(() => {
      displayPhone.value = (selected.value || {}).phone
        ? getFormattedPhone((selected.value || {}).phone as string)
        : "Not Applicable";
      displayHomePhone.value = (selected.value || {}).homePhone
        ? getFormattedPhone((selected.value || {}).homePhone as string)
        : "Not Applicable";
      displaySponsorPhone.value = ((selected.value || {}).sponsor || {}).phone
        ? getFormattedPhone(
            ((selected.value || {}).sponsor || {}).phone as string
          )
        : "Not Applicable";
      displayEmergencyContactPhone.value = (
        (selected.value || {}).emergencyContact || {}
      ).phone
        ? getFormattedPhone(
            ((selected.value || {}).emergencyContact || {}).phone as string
          )
        : "Not Applicable";
      lastAddressLine.value = `${(selected.value || {}).city}, ${
        (selected.value || {}).stateOrTerritory
      }  ${(selected.value || {}).zipCode}`;
    });

    async function deleteApplicant() {
      await store.dispatch(ApplicantActions.DELETE, {
        service: service,
        id: id.value,
      });
      router.push({
        name: "ApplicantList",
      });
    }

    function navigateToList() {
      router.push({
        name: "ApplicantList",
      });
    }

    function transformStatus() {
      if ((selected.value || {}).status) {
        const status = APPLICANT_STATUS_LIST.find(
          ({ value }) => value === (selected.value || {}).status
        );
        return (status || {}).text;
      }

      return "Not Applicable";
    }

    async function editApplicant() {
      router.push({
        name: "ApplicantUpdate",
        params: {
          id: ((selected.value || {}).id || "").toString(),
        },
      });
    }

    async function reactivateApplicant() {
      await store.dispatch(ApplicantActions.REACTIVATE, {
        service,
        id: id.value,
      });
      router.push({
        name: "ApplicantList",
      });
    }

    function getDate(date: string) {
      return moment(new Date(date).toISOString().substr(0, 10)).format(
        "MM/DD/YYYY"
      );
    }

    function getNoteLabel(note: ApplicantNote) {
      const author = users.value.filter(
        (user) => user.id === note.createdBy
      )[0];

      if (!note.updatedBy) {
        return `Author: ${(author || {}).firstName} ${
          (author || {}).lastName
        }---Created: ${getDate(note.createDate.toString())}`;
      } else {
        const editor = users.value.filter(
          (user) => user.id === note.updatedBy
        )[0];
        if (note.updatedBy === note.createdBy) {
          return `Author: ${(author || {}).firstName}---Created: ${getDate(
            note.createDate.toString()
          )}---Edited: ${getDate(note.updateDate.toString())} `;
        } else {
          return `Author: ${(author || {}).firstName} ${
            (author || {}).lastName
          }---Created: ${getDate(note.createDate.toString())}---Edited by: ${
            (editor || {}).firstName
          } ${(editor || {}).lastName}---${getDate(
            note.updateDate.toString()
          )}`;
        }
      }
    }

    function getSponsorRelationship() {
      if (selected.value && selected.value.sponsor) {
        return SPONSOR_RELATIONSHIP.filter(
          (relation) =>
            relation.value ===
            ((selected.value || {}).sponsor || {}).relationship
        ).map((relation) => relation.text)[0];
      } else return;
    }

    function getEmergencyContactRelationship() {
      if (!selected.value || !selected.value.emergencyContact) {
        return;
      }
      return CONTACT_RELATIONSHIP.filter(
        (relation) =>
          relation.value ===
          ((selected.value || {}).emergencyContact || {}).relationship
      ).map((relation) => relation.text)[0];
    }

    function getSpecialty(provider: Provider | undefined) {
      if (provider) {
        return SPECIALTIES.find(
          (specialty) => specialty.value === (provider || {}).specialty
        )?.text;
      } else return;
    }

    async function navigateToDog() {
      if (selected.value && selected.value.dog) {
        await store.dispatch(DogActions.SELECT, {
          id: selected.value.dog.id,
          service: dogService,
        });
        await router.push({
          name: "DogDetail",
          params: { id: (selected.value.dog.id || "").toString() },
        });
      }
    }

    return {
      loading,
      selected,
      getDate,
      lastAddressLine,
      photoSource,
      isDefaultPhoto,
      editApplicant,
      deleteApplicant,
      navigateToList,
      displayPhone,
      displayHomePhone,
      displayEmergencyContactPhone,
      action,
      applicantMilitaryService,
      applicantResponderService,
      reactivateApplicant,
      notes,
      getNoteLabel,
      getSponsorRelationship,
      getEmergencyContactRelationship,
      displaySponsorPhone,
      getSpecialty,
      transformStatus,
      applicationFile,
      homeVisitFile,
      medicalFiles,
      serviceEmploymentFiles,
      contractFile,
      navigateToDog,
      getFormattedPhone,
    };
  },
});
